 var plotf: any = $['plot'];

angular.module('app').directive('flot', ['$timeout', function ($timeout: any) {
  return {
    restrict: 'EA',
    template: '<div></div>',
    scope: {
      dataset: '=',
      options: '=',
      callback: '=',
      onPlotClick: '&',
      onPlotHover: '&',
      onPlotSelected: '&'
    },
    link: function (scope: any, element: any, attributes: any) {
      var plot: any = null;
      var width = attributes.width || '100%';
      var height = attributes.height || '100%';

      // Bug: Passing a jQuery object causes an infinite loop within Angular. Fail hard telling
      // users that they should pass us a jQuery expression as string instead.
      if ((((scope.options || {}).legend || {}).container) instanceof jQuery) {
        throw new Error('Please use a jQuery expression string with the "legend.container" option.');
      }

      if (!scope.dataset) {
        scope.dataset = [];
      }

      if (!scope.options) {
        scope.options = {
          legend: {
            show: false
          }
        };
      }

      var plotArea = $(element.children()[0]);

      plotArea.css({
        width: width,
        height: height
      });

      /**
       * funzione che scrive le data labels sul plot
       */
      let drawDataLabels = (plot: any) => {
        if(plot 
          && scope.options.dataLabels 
          && scope.options.dataLabels.show) {
          plot.getPlaceholder().children(".flot-data-label").remove();

          for (let el of plot.getData()) {
            if (el.datapoints 
              && el.datapoints.points 
              && el.datapoints.points.length > 0) {
                for(let i = 0; i < el.datapoints.points.length; i += el.datapoints.pointsize) {
                  let data = el.datapoints.points.slice(i, i + el.datapoints.pointsize);
                  let dataPoint = data[1];
                  if (dataPoint) {
                    let offset = plot.pointOffset({ x: data[0], y: data[1] });
                    let cssClass = scope.options.dataLabels.css && scope.options.dataLabels.css.class ? scope.options.dataLabels.css.class : "";
                    // arrotondo a 2 decimali il dato mostrato
                    if(typeof dataPoint === "number") {
                        if(dataPoint % 100 > 0) {
                          dataPoint = Math.round(dataPoint * 100) / 100;
                        }
                    }
                    let dataLabelDiv = $('<div class="flot-data-label ' + cssClass + '"><span>' + dataPoint + '</span></div>');
                    dataLabelDiv.css({
                      position: 'absolute',
                      left: offset.left,
                      top: offset.top,
                      color: el.bars.fillColor
                    }).appendTo(plotArea);
                    let dataLabelDivSpan = $(dataLabelDiv.children()[0]);
                    dataLabelDivSpan.css({
                      position: 'relative',
                      left: - dataLabelDiv.width() / 2,
                      top: - dataLabelDiv.height() - 1
                    });
                  }
                }
            }
          }
        }
      }

      var init = function () {
        var plotObj = plotf(plotArea, scope.dataset, scope.options);
        //var plotObj = $.plot(plotArea, scope.dataset, scope.options);
        
        drawDataLabels(plotObj);

        if (scope.callback) {
          scope.callback(plotObj);
        }

        return plotObj;
      };

      //
      // Events
      //

      plotArea.on('plotclick', function onPlotClick (event, pos, item) {
        $timeout(function onApplyPlotClick () {
          scope.onPlotClick({
            event: event,
            pos: pos,
            item: item
          });
        });
      });

      plotArea.on('plotselected', function onPlotSelected (event, ranges) {
        $timeout(function onApplyPlotSelected () {
          scope.onPlotSelected({
            event: event,
            ranges: ranges
          });
        });
      });

      plotArea.on('plothover', function onPlotHover (event, pos, item) {
        $timeout(function onApplyPlotHover () {
          scope.onPlotHover({
            event: event,
            pos: pos,
            item: item
          });
        });
      });
      //
      // Watches
      //

      var onOptionsChanged = function () {
        plot = init();

        plot.getPlaceholder().resize(() => {
          drawDataLabels(plot);
        });
      };

      var unwatchOptions = scope.$watch('options', onOptionsChanged, true);

      var onDatasetChanged = function (dataset: any) {
        if (plot) {
          plot.setData(dataset);
          plot.setupGrid();

          drawDataLabels(plot);

          return plot.draw();
        } else {
          plot = init();
        }
      };

      var unwatchDataset = scope.$watch('dataset', onDatasetChanged, true);

      attributes.$observe('width', function (value: any) {
        if (!value) return;
        width = value;
        plotArea.css('width', value);
      });

      attributes.$observe('height', function (value: any) {
        if (!value) return;
        height = value;
        plotArea.css('height', value);
      });

      //
      // Tear Down
      //

      element.on('$destroy', function onDestroy () {
        if(plotArea){
          plotArea.off('plotclick');
          plotArea.off('plothover');
          plotArea.off('plotselected');
        }

        if(plot){
          plot.shutdown();
        }
        unwatchDataset();
        unwatchOptions();
      });
    }
  };
}]);
